<template>
  <div class="homeContainer">
    <div class="swiperWrapper">
      <van-nav-bar
        title="开通VIP"
        left-arrow
        @click-left="onClickLeft"
      >
        <template #left>
          <span class="left_arrow">
            ←
          </span>
        </template>
      </van-nav-bar>
      <!-- 轮播图 -->
      <van-swipe class="my-swipe" :autoplay="3000" indicator-color="white">
        <van-swipe-item>
          <img src="../../assets/imgs/tdjj_s1.png" alt="" class="swiper_img">
        </van-swipe-item>
        <van-swipe-item>
          <img src="../../assets/imgs/tdjj_s2.png" alt="" class="swiper_img">
        </van-swipe-item>
        <van-swipe-item>
          <img src="../../assets/imgs/tdjj_s3.png" alt="" class="swiper_img">
        </van-swipe-item>
        <van-swipe-item>
          <img src="../../assets/imgs/tdjj_s4.png" alt="" class="swiper_img">
        </van-swipe-item>
        <van-swipe-item>
          <img src="../../assets/imgs/tdjj_s5.png" alt="" class="swiper_img">
        </van-swipe-item>
        <van-swipe-item>
          <img src="../../assets/imgs/tdjj_s6.png" alt="" class="swiper_img">
        </van-swipe-item>
        <van-swipe-item>
          <img src="../../assets/imgs/tdjj_s7.png" alt="" class="swiper_img">
        </van-swipe-item>
        <van-swipe-item>
          <img src="../../assets/imgs/tdjj_s8.png" alt="" class="swiper_img">
        </van-swipe-item>
      </van-swipe>
    </div>
    <!-- 开通会员 -->
    <div class="vipWrapper">
      <h4 class="title">
        <i class="icon line_left"></i>
        <span class="text">VIP会员特享</span>
        <i class="icon line_right"></i>
      </h4>
      <div class="content">
        <div class="content-item" v-for="item in vipLists" :key="item.title">
          <img :src="item.icon" alt="" class="content-item-img">
          <p class="content-item-desc">{{item.title}}</p>
        </div>
      </div>
    </div>
    <!-- 套餐推荐 -->
    <div class="recommandWrapper">
      <h4 class="title">
        <i class="icon line_left"></i>
        <span class="text">VIP会员特享</span>
        <i class="icon line_right"></i>
      </h4>
      <div class="recommandContent">
        <div class="recommand-item"
          :class="{
            bg_pay: seletPayMethod !== item.num,
            bg_pay_select: seletPayMethod === item.num,
            mr12: idx !== (payLists.length - 1)
          }"
          v-for="(item,idx) in products"
          :key="item.name"
        >
          <h4 class="title">{{item.name}}</h4>
          <p class="desc">{{item.every_day}}</p>
          <div class="totalPrice font">总价{{item.actual_price}}元</div>
        </div>
      </div>
    </div>
    <!-- 距离活动结束 -->
    <div class="activityEndWrapper">
      <i class="bell"></i>
      <span class="endTitle">活动结束：</span>
      <span class="time">{{time}}</span>
    </div>
    <!-- 支付方式 -->
    <div class="payMethodWrapper">
      <van-radio-group v-model="radio">
        <div class="pay-item">
          <div class="left">
            <img src="../../assets/imgs/tdjj_wx.png" alt="" class="left-img">
            <span class="text">微信安全支付</span>
          </div>
          <div class="right">
            <van-radio name="wx">
              <template #icon="props">
                <img class="icon-radio" :src="props.checked ? require('../../assets/imgs/tdjj_icon_checked.png') : ''" />
              </template>
            </van-radio>
          </div>
        </div>
        <div class="pay-item">
          <div class="left">
            <img src="../../assets/imgs/tdjj_wx.png" alt="" class="left-img">
            <span class="text">支付宝支付</span>
          </div>
          <div class="right">
            <van-radio name="zfb">
              <template #icon="props">
                <img class="icon-radio" :src="props.checked ? require('../../assets/imgs/tdjj_icon_checked.png') : ''" />
              </template>
            </van-radio>
          </div>
        </div>
      </van-radio-group>
    </div>
    <div class="pay_btn" @click="handlePayClick">
      立即领取
    </div>
    <div class="bottom"></div>

    <van-dialog v-model="showDialog" :show-confirm-button="false" class="leave_dialog">
      <div class="dialog-leave-img"/>
      <div class="openVip" @click="handleOpenVipClick"></div>
      <p class="confirmLeave">
        <span class="txt" @click="handleConfirmClick">狠心离开</span>
      </p>
    </van-dialog>
  </div>
</template>

<script>
import { getTDJJPayment } from '@/api/tdjj'
export default {
  data () {
    return {
      vipLists: [
        {
          icon: require('../../assets/imgs/tdjj_icon_scale.png'),
          title: '自由缩放'
        },
        {
          icon: require('../../assets/imgs/tdjj_icon_vr.png'),
          title: 'VR全景'
        },
        {
          icon: require('../../assets/imgs/tdjj_icon_3d.png'),
          title: '3D街景'
        },
        {
          icon: require('../../assets/imgs/tdjj_icon_hot.png'),
          title: '热门景点'
        },
        {
          icon: require('../../assets/imgs/tdjj_icon_vip.png'),
          title: '会员客服'
        },
        {
          icon: require('../../assets/imgs/tdjj_icon_map.png'),
          title: '高清地图'
        },
        {
          icon: require('../../assets/imgs/tdjj_icon_wx.png'),
          title: '卫星影像'
        },
        {
          icon: require('../../assets/imgs/tdjj_icon_location.png'),
          title: '自由定位'
        }
      ],
      seletPayMethod: '1',
      payLists: [
        {
          price: '1.08',
          month: '1个月',
          total: 98,
          num: '1'
        },
        {
          price: '0.65',
          month: '6个月',
          total: 118,
          num: '2'
        },
        {
          price: '0.0001',
          month: '终身卡',
          total: 138,
          num: '3'
        }
      ],
      products: [],
      time: '00:00:00',
      radio: 'wx',
      showDialog: false,
      next: null
    }
  },
  beforeRouteLeave (to, from, next) {
    this.showDialog = true
    this.next = next
  },
  mounted () {
    this.getPaymentLists()
  },
  methods: {
    // 获取支付套餐选项
    async getPaymentLists () {
      try {
        const sign = '07ad21fa030ce303b594f745d4f2a7f1'
        const params = {
          os: 'Android',
          osvers: '11',
          make: 'google',
          model: 'sdk_gphone_arm64',
          locale: 'en_US',
          version: '1.0.14.1',
          device_id: '7c28c7ff7cdf2104',
          app_id: 12,
          app_key: '0989d96c8baa608cf0d10223a2fe5022',
          android_id: '7c28c7ff7cdf2104',
          openudid: '7c28c7ff7cdf2104',
          clientudid: '8fced826-6da7-4106-8010-9e3dde8c747a',
          mac: '02:15:b2:00:00:00',
          channel: 'yyb',
          token: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJleHAiOjE2MzY3MTAwNDQsImp0aSI6IjEzMDI3OSIsImlhdCI6MTYzNDExODA0NCwiaXNzIjoiY29tLmdpdGtiLmdvLmFwcHNlcnZpY2UiLCJuYmYiOjE2MzQxMTgwNDQsInN1YiI6IjEyIn0.8VyFXVmAZ6scIZProDSDGDAynMRiE5fiTZI9tI6VMfE'
        }
        const headers = {
          sign
        }
        const { data } = await getTDJJPayment(params, headers)
        console.log('data:', data)
        this.products = data.products
      } catch (err) {
        console.log(`get payment fail:${err}`)
      } finally {
        //
      }
    },
    // 返回
    onClickLeft () {
      this.$router.push('/test')
    },
    // 立即领取
    handlePayClick () {
    },
    // 狠心离开
    handleConfirmClick () {
      this.next()
    },
    // 去开通
    handleOpenVipClick () {
      this.next(false)
      // 这里面随意发挥
    }
  }
}
</script>

<style lang="scss" scoped>
  .homeContainer {
    padding-bottom: 21px;
    .left_arrow {
      font-size: 20px;
      color: #fff;
    }
    .swiperWrapper {
      display: block;
      width: 375px;
      height: 160px;
      background: url('../../assets/imgs/tdjj_bg_black.png') no-repeat center center;
      background-size: cover;
    }
    .swiper_img {
      width: 100%;
    }
    .title {
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 0;
      .icon {
        display: inline-block;
        width: 43px;
        height: 6px;
        &.line_left {
          background: url('../../assets/imgs/tdjj_line_left.png') no-repeat center center;
          background-size: cover;
        }
        &.line_right {
          background: url('../../assets/imgs/tdjj_line_right.png') no-repeat center center;
          background-size: cover;
        }
      }
      .text {
        margin: 0px 15px;
        font-size: 19px;
        font-weight: 500;
        color: #333333;
        line-height: 27px;
      }
    }
    .vipWrapper {
      margin-top: 80px;
      .content {
        margin-left: 14px;
        width: 347px;
        height: 172px;
        background: #FFFAF4;
        border-radius: 14px;
        margin-top: 14px;
        display: flex;
        flex-wrap: wrap;
        .content-item {
          width: 25%;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          .content-item-img {
            width: 42px;
            height: 42px;
          }
          .content-item-desc {
            margin: 0;
            font-size: 15px;
            font-weight: 500;
            color: #333333;
            line-height: 20px
          }
        }
      }
    }
    .recommandWrapper {
      margin-top: 20px;
      .mr12 {
        margin-right: 12px;
      }
      .recommandContent {
        margin-top: 7px;
        padding: 0 14px;
        display: flex;
        .recommand-item {
          position: relative;
          display: inline-block;
          width: 108px;
          height: 124px;
          text-align: center;
          &.bg_pay {
            background: url('../../assets/imgs/tdjj_bg_pay.png') no-repeat center center;
            background-size: cover;
            .font {
              color: #DEB676;
            }
          }
          &.bg_pay_select {
            background: url('../../assets/imgs/tdjj_bg_pay_select.png') no-repeat center center;
            background-size: cover;
            .font {
              color: #fff;
            }
          }
          .title {
            margin-top: 21px;
            font-size: 13px;
            font-weight: 600;
            color: #333330;
            line-height: 17px;
          }
          .desc {
            margin-top: 17px;
            font-size: 14px;
            font-weight: 500;
            color: #DEB676;
            line-height: 23px
          }
          .totalPrice {
            position: absolute;
            left: 0px;
            right: 0px;
            bottom: 0px;
            height: 35px;
            font-size: 17px;
            font-weight: 500;
            line-height: 35px;
          }
        }
      }
    }
    .activityEndWrapper {
      padding-top: 6px;
      box-sizing: border-box;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-left: 14px;
      margin-top: 16px;
      width: 347px;
      height: 43px;
      background: url('../../assets/imgs/tdjj_timeEnd.png') no-repeat center center;
      background-size: cover;
      .bell {
        display: inline-block;
        width: 14px;
        height: 14px;
        background: url('../../assets/imgs/tdjj_icon_bell.png') no-repeat center center;
        background-size: cover;
      }
      .endTitle {
        margin-left: 6px;
        color: #333;
        font-size: 14px;
      }
      .time {
        color: #FE4065;
        font-size: 14px;
      }
    }
    .payMethodWrapper {
      margin-left: 14px;
      width: 347px;
      .pay-item {
        margin-top: 16px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .left {
          display: flex;
          align-items: center;
          .left-img {
            display: inline-block;
            width: 24px;
            height: 24px;
            background: url('../../assets/imgs/tdjj_wx.png') no-repeat center center;
            background-size: cover;
          }
          .text {
            margin-left: 10px;
            font-size: 15px;
            font-weight: 500;
            color: #333333;
            line-height: 20px;
          }
        }
        .right {
          .icon-radio {
            display: inline-block;
            width: 18px;
            height: 18px;
          }
        }
      }
    }
    .pay_btn {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 22px;
      margin-left: 14px;
      width: 347px;
      height: 52px;
      background: linear-gradient(90deg, #F9E2AC 0%, #DEB676 100%);
      border-radius: 28px;
      font-size: 19px;
      font-weight: 500;
      color: #71510F;
    }
    .leave_dialog {
      .dialog-leave-img {
        display: block;
        width: 100%;
        height: 445px;
        background: url('../../assets/imgs/tdjj_leave.png') no-repeat center center;
        background-size: contain;
      }
      .openVip {
        margin: auto;
        position: relative;
        top: -40px;
        display: block;
        width: 279px;
        height: 53px;
        background: url('../../assets/imgs/tdjj_open_vip.png') no-repeat center center;
        background-size: cover;
      }
      .confirmLeave {
        text-align: center;
        margin: 0;
        position: relative;
        top: -32px;
        .txt {
          font-size: 15px;
          font-weight: 500;
          color: #009BFF;
          line-height: 20px;
        }
      }
    }
  }
  ::v-deep {
    .van-nav-bar__content {
      background: #000;
      .van-nav-bar__title {
        color: #fff;
      }
    }
    .van-nav-bar {
      &::after {
        border-bottom-width: 0px;
      }
    }
    .van-dialog {
      background-color: #fff0;
    }
  }
</style>
