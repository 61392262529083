
import request from '@/utils/request'

// 获取街景支付页的套餐选项
export function getTDJJPayment (data, headers) {
  return request({
    url: '/api/v2/payment/buy',
    method: 'post',
    data,
    headers
  })
}
